<template>
  <div class="configuration">
    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
      <el-menu-item v-for="(item,index) in NavMenuList" :key="item.value" :index="index + ''">{{item.name}}</el-menu-item>
    </el-menu>
    <el-table :data="tableData" style="width: 100%" row-key="id" :tree-props="{children: 'children'}">
      <el-table-column label="类型" prop="type_name" width="200">
      </el-table-column>
      <el-table-column label="标题名称" prop="title">
      </el-table-column>
      <!-- <el-table-column prop="preven">
      </el-table-column> -->
      <el-table-column align="right">
        <!-- eslint-disable-next-line -->
        <template slot="header" slot-scope='scope'>
          <el-button type="primary" round @click="dialogVisibleAdd = true">新增模块</el-button>
        </template>
        <template slot-scope="scope">
          <el-button v-if="scope.row.type == 'modular' || scope.row.type == 'tab'" size="mini" @click="handleAdd(scope.row.id)">添加内容</el-button>
          <el-button v-if="scope.row.type == 'modular' && scope.row.pid == 0" size="mini" type="primary" @click="tabAdd(scope.row.id)">添加TAB切换</el-button>
          <el-button v-if="scope.row.type !== 'modular' && scope.row.type !== 'tableList' && scope.row.type !== 'tab'" size="mini" type="success" @click="editModularList(scope.row)">编辑单元内容</el-button>
          <el-button v-if="scope.row.type == 'modular'" size="mini" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button v-if="scope.row.type == 'tableList'" size="mini" type="primary" @click="editabl(scope.row)">编辑tab</el-button>
          <el-button v-if="scope.row.type == 'tab'" size="mini" type="info" @click="addtabMordel(scope.row)">添加tab下模块</el-button>
          <el-button  size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加模块 -->
    <el-dialog title="添加模块" :visible.sync="dialogVisibleAdd" width="30%" >模块名称
      <el-input placeholder="请输入模块名称" v-model="modularName"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleAdd = false">取 消</el-button>
        <el-button type="primary" @click="addModular">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加内容 -->
    <el-dialog title="添加模块下内容" :visible.sync="assemblyAdd" width="60%">
      <el-tag v-for="item in items" :key="item.label" :type="item.type" effect="dark" @click="changeTag(item.label)">
        {{ item.label }}
      </el-tag>
        <div class="titleNme">{{lableNmae}}</div>
        <el-input v-model="input" placeholder="请输入标题" v-if="lableNmae"></el-input>
        <span v-if="lableNmae">默认值</span>
        <el-input v-model="defaultValue" placeholder="默认值" v-if="lableNmae"></el-input>
        <!-- 单选列 -->
        <div class="radio" v-if="isType === 'radio' || isType === 'elementradio' || isType === 'checkbox'">
          <h2 class="name_title">添加选项内容</h2>
          <el-tag :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false" @close="handleClose(tag)">
            {{tag}}
          </el-tag>
          <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm">
          </el-input>
          <el-button v-else class="button-new-tag" size="small" @click="showInput">点击添加选项</el-button>
        </div>
        <!-- 判断选择 -->
        <div class="whether" v-if="isType == 'whether'">
          <p>列如</p>
          <img src="../assets/img/1611903405(1).png" alt="">
          <el-form label-position="left" label-width="80px" :model="whetherForm" :rules="rules" ref="whetherForm">
            <el-form-item label="选项1" prop="optionone">
              <el-input v-model="whetherForm.optionone" placeholder="列如 ： 不需要"></el-input>
            </el-form-item>
            <el-form-item label="选项2" prop="optiontow">
              <el-input v-model="whetherForm.optiontow" placeholder="列如 ： 需要"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <!-- 日期范围 -->
        <div class="time" v-if="isType == 'timezone'">
          <div class="block">
            <span class="demonstration">请选择开始日期和结束日期： </span>
            <el-date-picker
              v-model="time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </div>
        <!-- input -->
        <div class="isinput" v-if="isType == 'input' || isType == 'leftinput' || isType == 'topinput'">
          <el-radio-group v-model="radios">
            <el-radio :label="1">样式1
              <img src="../assets/img/input.png" alt="">
            </el-radio>
            <el-radio :label="2">样式2
              <img src="../assets/img/leftinput.png" alt="">
            </el-radio>
            <el-radio :label="3">样式3
              <img src="../assets/img/topinput.png" alt="">
            </el-radio>
            <el-radio :label="4">样式4文本域(多行)
              <img src="../assets/img/topinput.png" alt="">
            </el-radio>
          </el-radio-group>
        </div>
         <div class="open" v-if="isType">
          <el-switch v-model="other"  active-color="#13ce66" inactive-color="#ff4949">
          </el-switch> <span>(是否为必填)</span>
        </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="assemblyAdd = false">取 消</el-button>
        <el-button type="primary" @click="addAssembly">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑内容 -->
    <el-dialog :title="titlexz"
      :visible.sync="dialogVisibleradio"
      width="60%">
      <div class="titleNme">{{lableNmae}}</div>
        <el-input v-model="input" placeholder="请输入标题" v-if="lableNmae"></el-input>
        <div v-if="isType == 'input' || isType == 'leftinput' || isType == 'topinput'">
          <span>默认值</span>
          <el-input v-model="defaultValue" placeholder="默认值"></el-input>
        </div>
        <!-- 单选  单选列  多选 -->
        <div class="radio" v-if="isType == 'radio' || isType == 'elementradio' || isType == 'checkbox'">
          <h2 class="name_title">修改单选内容</h2>
          <el-tag :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false" @close="handleClose(tag)">
            {{tag}}
          </el-tag>
          <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm">
          </el-input>
          <el-button v-else class="button-new-tag" size="small" @click="showInput">点击添加选项</el-button>
        </div>
        <!-- 判断选择 -->
        <div class="whether" v-if="isType == 'whether'">
          <p>列如</p>
          <img src="../assets/img/1611903405(1).png" alt="">
          <el-form label-position="left" label-width="80px" :model="whetherForm" :rules="rules" ref="whetherForm">
            <el-form-item label="选项1" prop="optionone">
              <el-input v-model="whetherForm.optionone" placeholder="列如 ： 不需要"></el-input>
            </el-form-item>
            <el-form-item label="选项2" prop="optiontow">
              <el-input v-model="whetherForm.optiontow" placeholder="列如 ： 需要"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <!-- 日期范围 -->
        <div class="time" v-if="isType == 'timezone'">
          <div class="block">
            <span class="demonstration">请选择开始日期和结束日期： </span>
            <el-date-picker
              v-model="time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </div>
        <!-- input -->
        <!-- v-if="isType == 'input' || isType == 'leftinput' || isType == 'topinput'" -->
        <div class="isinput" v-if="isType == 'inputs'">
          <el-radio-group v-model="radios">
            <el-radio :label="1">样式1
              <img src="../assets/img/input.png" alt="">
            </el-radio>
            <el-radio :label="2">样式2
              <img src="../assets/img/leftinput.png" alt="">
            </el-radio>
            <el-radio :label="3">样式3
              <img src="../assets/img/topinput.png" alt="">
            </el-radio>
            <el-radio :label="4">样式4文本域(多行)
              <img src="../assets/img/topinput.png" alt="">
            </el-radio>
          </el-radio-group>
        </div>
        <div class="open" v-if="isType">
          <el-switch v-model="other" active-color="#13ce66" inactive-color="#ff4949">
          </el-switch> <span>(是否为必填)</span>
        </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleradio = false">取 消</el-button>
        <el-button type="primary" @click="radioEdit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加tab -->
    <el-dialog title="提示" :visible.sync="isShowtab" width="50%">
      <div class="tablist">
        <div class="titleNme">标题</div>
        <el-input v-model="input" placeholder="请输入标题"></el-input>
        <h2 class="name_title">添加tab</h2>
        <el-tag :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false" @close="handleClose(tag)">
          {{tag}}
        </el-tag>
        <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm">
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput">点击添加选项</el-button>
        <el-tabs v-model="acen" type="card" v-if="dynamicTags.length > 0">
          <el-tab-pane :label="item" v-for="(item,index) in dynamicTags" :key="item" :name="index + ''" >{{item}}</el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowtab = false">取 消</el-button>
        <el-button type="primary" @click="addAndEdittab">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加tab下模块 -->
    <el-dialog title="添加模块" :visible.sync="addtabMor" width="30%" >模块名称
      <el-input placeholder="请输入模块名称" v-model="addtabMorName"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addtabMor = false">取 消</el-button>
        <el-button type="primary" @click="addtM">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 模块
// 模块下的table
// table下的模块
export default {
  components: {
    // inputCreate
  },
  data() {
    return {
      loading: null,
      activeIndex: "0",
      isActive: 1,
      NavMenuList: [
        { index: "1", text: "项目基本信息" },
        { index: "2", text: "硬件配置" },
        { index: "3", text: "软件配置-X86" },
        { index: "4", text: "软件配置-ARM" },
        { index: "5", text: "随机配件及资料" },
        { index: "6", text: "品牌及服务" }
      ],
      items: [
        { type: "", label: "单选" },
        { type: "", label: "单选列" },
        { type: "success", label: "多选" },
        { type: "info", label: "判断选择" },
        { type: "danger", label: "区间" },
        { type: "warning", label: "日期范围" },
        { type: "", label: "输入框" }
      ],
      activeName: "basic",
      i: 0,
      tableData: [],
      chid: 0, // 添加内容的模块id
      lableNmae: "", //选择的添加内容
      dialogVisibleAdd: false, // 添加模态框
      assemblyAdd: false, // 添加内容
      dialogVisibleradio: false, // 编辑选择内容
      titlexz: "",
      modularName: "", //模块名称
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
      other: false, // 是否开启其他
      isShowtab: false,
      input: "", // 内容名称
      isType: "", //添加的类型
      content: {
        list: [],
        state: false
      },
      cid: "",
      whetherForm: {
        optionone: "",
        optiontow: ""
      },
      rules: {
        optionone: [{ required: true, message: "内容", trigger: "blur" }],
        optiontow: [{ required: true, message: "内容", trigger: "blur" }]
      },
      time: [
        // "Tue Jan 05 2021 00:00:00 GMT+0800 (中国标准时间)",
        // "Mon Feb 01 2021 00:00:00 GMT+0800 (中国标准时间)"
      ],
      radios: "",
      acen: "1",
      isedit: false,
      addtabMID: "",
      addtabMorName: "",
      addtabMor: false,
      defaultValue: ""
    };
  },
  created() {
    this.activeName = "basic";
    this.getTab();
    this.getTableData();
  },
  mounted() {},
  methods: {
    // 整个页面加载loading
    openFullScreen2() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
    },
    // 获取tab导航数据
    async getTab() {
      this.openFullScreen2();
      const res = await this.axios.get("api/backend/templates");
      console.log(res);
      if (res.status_code === 200) {
        let list = res.data;
        this.NavMenuList = list;
        this.loading.close();
      } else if (res.status_code === 401) {
        this.loading.close();
        this.$router.push({ path: "/login" });
      }
    },
    // 切换tab导航
    handleSelect(key) {
      console.log(key);
      this.isActive = key;
      this.activeName = this.NavMenuList[key].value;
      console.log(this.activeName);
      this.getTableData();
    },
    // 添加table
    tabAdd(id) {
      this.chid = id;
      this.dynamicTags = [];
      this.isType = "tableList";
      this.isShowtab = true;
      this.isedit = false;
    },
    //  添加tab
    addAndEdittab() {
      this.content.list = this.dynamicTags;
      if (this.isedit === false) {
        this.add();
      } else {
        this.edit();
      }
      this.isShowtab = false;
    },
    // 修改tab
    editabl(row) {
      console.log(row);
      this.cid = row.id;
      console.log(this.cid);
      this.isType = row.type;
      this.dynamicTags = row.content.list;
      this.input = row.title;
      this.isShowtab = true;
      this.isedit = true;
    },
    // 获取table列表数据
    async getTableData() {
      this.openFullScreen2();
      const res = await this.axios.get(
        `/api/backend/template/${this.activeName}`
      );
      if (res.status_code === 200) {
        this.tableData = res.data;
        console.log(this.tableData);
        this.loading.close();
      }
    },
    // 打开添加内容的模态框
    handleAdd(id) {
      this.chid = id; //对应id
      console.log(this.chid);
      this.input = ""; // 标题
      this.lableNmae = "";
      this.dynamicTags = []; // 选项内容
      this.isType = "";
      this.whetherForm.optionone = ""; // 清空判断选择内容
      this.whetherForm.optiontow = "";
      this.time = []; // 清空时间范围
      this.defaultValue = "";
      this.assemblyAdd = true;
    },
    // 添加模块下内容选择对应类型
    changeTag(lable) {
      console.log(lable);
      this.lableNmae = lable;
      if (lable === "单选") {
        this.isType = "radio";
      } else if (lable === "单选列") {
        this.isType = "elementradio";
      } else if (lable === "多选") {
        this.isType = "checkbox";
      } else if (lable === "判断选择") {
        this.isType = "whether";
      } else if (lable === "区间") {
        this.isType = "pricerange";
      } else if (lable === "日期范围") {
        this.isType = "timezone";
      } else if (lable === "输入框") {
        this.isType = "input";
        console.log(this.isType);
      }
    },
    // 确认添加模块下内容
    async addAssembly() {
      // if (this.input === "") {
      //   this.$message.error("请输入标题");
      //   return false;
      // }
      this.content.state = this.other;
      if (
        this.isType === "radio" ||
        this.isType === "elementradio" ||
        this.isType === "checkbox" ||
        this.isType === "tableList"
      ) {
        this.content.list = this.dynamicTags;
        console.log(this.content);
        this.add();
      } else if (this.isType === "whether") {
        this.content.list = [];
        try {
          await this.$refs.whetherForm.validate();
          this.content.list.push(this.whetherForm.optionone);
          this.content.list.push(this.whetherForm.optiontow);
          console.log(this.content);
          this.add();
        } catch (e) {
          this.$message.error(e);
          return false;
        }
      } else if (this.isType === "pricerange") {
        this.add();
      } else if (this.isType === "timezone") {
        if (this.time.length <= 0) {
          this.$message.error("请选择时间范围");
        } else {
          this.content.list = this.time;
          this.add();
        }
      } else if (this.isType === "input") {
        if (this.radios === "") {
          this.$message.error("请选择样式");
        } else {
          console.log(this.radios);
          if (this.radios === 1) {
            this.isType = "input";
          } else if (this.radios === 2) {
            this.isType = "leftinput";
          } else if (this.radios === 3) {
            this.isType = "topinput";
          } else if (this.radios === 4) {
            this.isType = "textarea";
          }
          this.content.list = this.radios;
          console.log(this.isType);
          this.add();
        }
      }
    },
    // 添加内容的接口
    async add() {
      const res = await this.axios.post("api/backend/template", {
        title: this.input,
        template_type: this.activeName,
        type: this.isType,
        pid: this.chid,
        content: this.content,
        default_text: this.defaultValue
      });
      if (res.status_code === 200) {
        this.assemblyAdd = false;
        this.isType = ""; // 类型清空
        this.lableNmae = ""; // 名称清空
        this.dynamicTags = []; // 选项列表清空
        this.defaultValue = "";
        this.getTableData(); // 从新请求tab数据
      } else {
        this.$message.error(res.error);
      }
    },
    // 编辑模块下内容 用于数据回显
    editModularList(row) {
      console.log(row);
      this.cid = row.id;
      this.isType = row.type;
      this.lableNmae = row.type_name;
      this.input = row.title;
      this.dialogVisibleradio = true;
      this.other = row.content.state;
      this.defaultValue = "";
      if (
        row.type === "radio" ||
        row.type === "elementradio" ||
        row.type === "checkbox"
      ) {
        if (row.content != null) {
          this.dynamicTags = row.content.list;
        } else {
          this.dynamicTags = [];
        }
      }
      if (row.type === "radio") {
        // 编辑单选
        this.titlexz =
          "编辑单选内容（内容限制4个字符 如果单选内容过长请选择单选列）";
      } else if (row.type === "elementradio") {
        this.titlexz = "编辑单选列";
      } else if (row.type === "checkbox") {
        this.titlexz = "编辑复选框内容";
      } else if (row.type === "whether") {
        this.titlexz = "编辑判断选择";
        this.whetherForm.optionone = row.content.list[0];
        this.whetherForm.optiontow = row.content.list[1];
      } else if (row.type === "pricerange") {
        this.titlexz = "编辑区间";
      } else if (row.type === "timezone") {
        this.titlexz = "编辑时间范围";
        this.time = row.content.list;
      } else if (row.type === "input") {
        this.titlexz = "编辑文本框";
        this.radios = row.content.list;
        if (row.default_text) {
          this.defaultValue = row.default_text;
        } else {
          this.defaultValue = "";
        }
      }
    },
    // 确认的编辑模块下内容
    async radioEdit(type) {
      console.log(this.cid);
      console.log(this.isType);
      this.content.state = this.other;
      if (this.isType === "radio") {
        this.content.list = [];
        this.content.list = this.dynamicTags;
        this.edit();
      } else if (
        this.isType === "elementradio" ||
        this.isType === "checkbox" ||
        this.isType === "tableList"
      ) {
        this.content.list = this.dynamicTags;
      } else if (this.isType === "whether") {
        this.content.list = [];
        this.content.list.push(this.whetherForm.optionone);
        this.content.list.push(this.whetherForm.optiontow);
        this.edit();
      } else if (this.isType === "pricerange") {
        this.edit();
      } else if (this.isType === "timezone") {
        this.content.list = [];
        this.content.list = this.time;
        this.edit();
      } else if (
        this.isType === "input" ||
        this.isType === "leftinput" ||
        this.isType === "topinput"
      ) {
        this.content.list = [];
        this.content.list = this.radios;
        this.edit();
      }
    },
    // 编辑接口
    async edit() {
      const res = await this.axios.put(`api/backend/template/${this.cid}`, {
        title: this.input,
        content: this.content,
        default_text: this.defaultValue
      });
      if (res.status_code === 200) {
        this.$message.success("修改成功");
        this.dialogVisibleradio = false;
        this.defaultValue = "";
        this.getTableData();
      } else {
        this.$message.error(res.error);
      }
    },
    // 编辑名称
    handleEdit(row) {
      console.log(row);
      this.openModifyModular(row);
    },
    // 修改名称
    openModifyModular(row) {
      this.$prompt("请输入名称", "修改" + row.title + "名称", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(({ value }) => {
          this.editModular(row, value);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消编辑"
          });
        });
    },
    // 编辑模块名称接口
    async editModular(row, value) {
      const res = await this.axios.put(`api/backend/template/${row.id}`, {
        title: value
      });
      console.log(res);
      if (res.status_code === 200) {
        this.$message({
          type: "success",
          message: "修改成功"
        });
        this.getTableData();
      } else {
        this.$message.error(res.error);
      }
    },

    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      console.log(this.inputValue);
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },

    // 删除
    handleDelete(row) {
      console.log(row);
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.dele(row);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
      // this.tableData = this.tableData.filter(item => item.id !== row.id);
    },
    // 删除接口
    async dele(row) {
      const res = await this.axios.delete(`api/backend/template/${row.id}`);
      if (res.status_code === 200) {
        this.$message({
          type: "success",
          message: "删除成功!"
        });
        this.getTableData();
      } else {
        this.$message({
          type: "error",
          message: res.error
        });
      }
    },
    // 新增模块
    async addModular() {
      console.log("添加");
      const res = await this.axios.post("api/backend/template", {
        title: this.modularName,
        template_type: this.activeName,
        type: "modular"
      });
      console.log(res);
      if (res.status_code == 200) {
        this.dialogVisibleAdd = false;
        this.getTableData();
      }
    },
    // 新增tab下面的模块标题
    async addtabMordel(row) {
      console.log(row);
      this.addtabMor = true;
      this.addtabMID = row.id;
    },
    async addtM() {
      const res = await this.axios.post("api/backend/template", {
        title: this.addtabMorName,
        template_type: this.activeName,
        type: "modular",
        pid: this.addtabMID
      });
      console.log(res);
      if (res.status_code == 200) {
        this.addtabMor = false;
        this.getTableData();
      }
    }
  }
};
</script>
<style lang="less" scoped>
.configuration {
  .box_container {
    display: flex;
    height: 100%;
    margin-top: 20px;
    .from_create {
      flex: 1;
      height: 100%;
      // background-color: skyblue;
      padding-right: 20px;
    }
    .mobile_phone {
      flex: 1;
      background-color: hotpink;
    }
  }
  .el-tag {
    cursor: pointer;
    margin-left: 10px;
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
  .el-tag--dark {
    transition: all 0.5s;
  }
  .el-tag--dark:hover {
    transform: scale(1.2);
  }
  .open {
    margin-top: 20px;
    margin-left: 20px;
  }
  .titleNme {
    margin-top: 0.2rem /* 20/100 */;
    // margin-bottom: .4rem /* 40/100 */;
  }
  .radio {
    margin-top: 0.4rem /* 40/100 */;
  }
  .name_title {
    margin-bottom: 0.2rem /* 20/100 */;
  }
  .whether {
    margin-top: 20px;
    img {
      width: 50%;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    p {
      color: red;
    }
  }
  .time {
    .block {
      margin-top: 0.5rem; /* 50/100 */
    }
  }
  .isinput {
    .el-radio-group {
      // float: left;
      .el-radio {
        width: 100%;
        margin-top: 0.2rem; /* 20/100 */
        img {
          width: 50%;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
          margin-top: 0.1rem /* 10/100 */;
        }
      }
    }
  }
  .tablist {
    .el-tabs {
      margin-top: 0.2rem; /* 20/100 */
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .name_title {
      margin-top: 0.2rem; /* 20/100 */
    }
  }
}
</style>
